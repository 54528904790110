// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-agent-login-js": () => import("./../../../src/pages/agent-login.js" /* webpackChunkName: "component---src-pages-agent-login-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insured-login-js": () => import("./../../../src/pages/insured-login.js" /* webpackChunkName: "component---src-pages-insured-login-js" */),
  "component---src-pages-make-a-payment-js": () => import("./../../../src/pages/make-a-payment.js" /* webpackChunkName: "component---src-pages-make-a-payment-js" */),
  "component---src-pages-premium-finance-js": () => import("./../../../src/pages/premium-finance.js" /* webpackChunkName: "component---src-pages-premium-finance-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-person-template-js": () => import("./../../../src/templates/person-template.js" /* webpackChunkName: "component---src-templates-person-template-js" */),
  "component---src-templates-stories-template-js": () => import("./../../../src/templates/stories-template.js" /* webpackChunkName: "component---src-templates-stories-template-js" */)
}

